import React from "react";

import ourstrategies from "./ourstrategiesdata";
import Styles from "./Styles.module.css";
import ImageWithLoader from "../../../hooks/ImageWithLoader";

const OurStrategiesCards = () => {
  return (
    <div className={Styles.container}>

      <div className={Styles.themesContainer}>
        {ourstrategies.map((item) => (
          <div key={item.id} className={Styles.productCard}>
            <ImageWithLoader src={item.imageUrl} alt={item.title} />
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurStrategiesCards;
