import { useState } from "react";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ImageWithLoader = ({ src, alt, className }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="relative w-full h-full overflow-hidden">
      {/* Skeleton Loader */}
      {!loaded && (
        <Skeleton
          className="absolute inset-0 w-full h-full"
          height="100%"
          width="100%"
        />
      )}

      {/* Actual Image with Blur-in Effect */}
      <motion.img
        src={src}
        alt={alt}
        onLoad={() => setLoaded(true)}
        initial={{ filter: "blur(10px)" }}
        animate={{ filter: loaded ? "blur(0px)" : "blur(10px)" }}
        transition={{ duration: 1 }}
        className={`w-full h-full object-cover ${className}`}
      />
    </div>
  );
};

export default ImageWithLoader;
