import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";
import ImageWithLoader from "../../hooks/ImageWithLoader";

const LandNaturalResourceContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Land & Natural Resource Management </h1>
            <p>
              Land is a critical resource for social and economic development,
              yet its access and utilization remain contentious issues in many
              parts of Kenya. The unequal distribution and deprivation of land,
              particularly among poor and marginalized communities, continue to
              exacerbate economic disparities. In some regions, disputes over
              land and natural resources have escalated into violent conflicts,
              undermining peace, stability, and sustainable development.
            </p>
            <div className={Styles.productCard}>
              <h3>Our Programs</h3>
              {list.map((item, index) => (
                <li key={index}>
                  <Link to={`/${item}`}>{item}</Link>
                </li>
              ))}
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <ImageWithLoader
                src="/aboutus/aboutusswo.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div className={Styles.bodytext}>
          <h4>Our Approach</h4>
          <p>
            At Sustainable World Organization (SWO), we are committed to
            addressing these pressing challenges by working closely with local
            communities, as well as local and national governments. Through
            dialogue, advocacy, and capacity-building, we tackle the root causes
            of land disputes and resource mismanagement. Our approach focuses on
            fostering understanding, promoting fair policies, and implementing
            sustainable solutions that ensure equitable access and utilization
            of land. By empowering communities and promoting transparency, we
            aim to transform land and resource management into a tool for unity
            and progress, rather than division and conflict.
          </p>
         
          
        </div>
      </div>
    </div>
  );
};

export default LandNaturalResourceContent;

const list = [
  "Student Mentorship",
  "Economic Empowerment",
  "Education",
  "Access to Health",
  "Peace and Cohesion",
  "Land & Natural Resource Management",
  "Resilience | Social Safety Net| Emergency Response",
  "Good Governance",
  "Holistic School-Based Peer Mentorship Boot Camp",
];
