// Gallery.js
import React from 'react';
import { Link } from 'react-router-dom';
import galleryData from './gallery/gallerydata';
import './gallery/styles.css';
import ImageWithLoader from '../hooks/ImageWithLoader';


const Gallery = () => {
  
  return (
    <div className="gallery-container">
      {galleryData.map((item) => (
        <Link key={item.id}  to={`/Gallery/${item.category}`} className="gallery-card">
          
          <ImageWithLoader src={item.imageUrl} alt={item.title} />
          <h3>{item.title}</h3>
        </Link>
      ))}
    </div>
  );
};

export default Gallery;
//  <Link key={item.id} to={`/gallery/${item.id}`} className="gallery-card"></Link>