import React from "react";
import Styles from "./Styles.module.css";
import Slider3 from "./aboutussliders/Slider3";
import SkeletonDisplay from "../hooks/skeletonDisplay";
import ImageWithLoader from "../hooks/ImageWithLoader";


function AboutUs() {
 

  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <ImageWithLoader
              src="/aboutus/aboutus.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button>AboutUs</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <Slider3 />
        
      </div>
    </div>
  );
}

export default AboutUs;
