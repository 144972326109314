import React from "react";
import Styles from "./Styles.module.css";

import SkeletonDisplay from "../hooks/skeletonDisplay";
import ImageWithLoader from "../hooks/ImageWithLoader";

function Header() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <ImageWithLoader
              src="/aboutus/aboutus.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button>Engage | Empower | Transform</button>
        </div>
      </div>
    </div>
  );
}

export default Header;
