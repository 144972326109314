import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Styles from "./Styles.module.css";
import ImageWithLoader from "../../../hooks/ImageWithLoader";

const PartnersCarousel = () => {
  const items = [
    {
      title: "Isiolo County Government",
      description: "Isiolo County Government",
      imageUrl: "/partners/isiolocounty.jpeg",
      link: "#",
    },
    {
      title: "Women Initiative for Development",
      description: "Women Initiative for Development",
      imageUrl: "/partners/womeninitiativesfordevelopment.jpeg",
      link: "#",
    },
    {
      title: "African Women Studies Centre",
      description: "African Women Studies Centre",
      imageUrl: "/partners/africanwomenstudiescentre.jpg",
      link: "#",
    },
    {
      title: "Every Girls Dream",
      description: "Every Girls Dream",
      imageUrl: "/partners/everygirlsdream.jpg",
      link: "#",
    },
    {
      title: "Women Political Alliance",
      description: "Women Political Alliance",
      imageUrl: "/partners/womenpoliticalalliance.png",
      link: "Women Political Alliance",
    },
  
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items:1,
    },
  };

  return (
    <Carousel responsive={responsive}>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            padding: "0.5rem",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            textAlign: "center",
            margin: "1rem",
          }}
        >
          <Link to={item.link}>
            <ImageWithLoader
              src={item.imageUrl}
              fill
              alt={item.title}
              className={Styles.image}
            />
          </Link>
        </div>
      ))}
    </Carousel>
  );
};

export default PartnersCarousel;
