import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../hooks/skeletonDisplay";
import PartnershipContent from "./sliders/partnershipcontent";
import ImageWithLoader from "../hooks/ImageWithLoader";

function Partnerships() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <ImageWithLoader
              src="/aboutus/partnerships.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button> PartnerShips</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <PartnershipContent />
      </div>
    </div>
  );
}

export default Partnerships;
