import React from "react";
import Styles from "./Styles.module.css";

import SkeletonDisplay from "../../hooks/skeletonDisplay";
import ImageWithLoader from "../../hooks/ImageWithLoader";

const GovernanceContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}></div>
        <div className={Styles.bodytext}>
          <h3>OUR GOVERNANCE STRUCTURE</h3>
          <p>
            At SWO is a women-led, women & youth-focused organisation, governed
            by a 7-member Board of Directors. The Board is responsible for
            policy formation, fund-raising, lobbying and advocacy, financial
            oversight and overall decision-making, including oversight over the
            Management Team. The day-to-day affairs of the organisation is
            managed by 3-member Management Team supported by competent and
            dedicated Programme Team and a group of volunteers. SWO has
            coordination office in Nairobi, and liaison office and staff in the
            Isiolo. SWO plans to open other liaison offices in other counties.
          </p>
          <SkeletonDisplay>
            <ImageWithLoader
              src="/whoweare/womengovernance.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <p>
            SWO has developed clear organisation policies and guidelines which
            stipulates roles and responsibilities of different institutions and
            duties and responsibilities of all staff that guides their
            operations. We seek consensus in our endeavors and strive to build a
            transparent and accountable organisation which serves the interest
            of women and girls in Kenya. We comply with all laid out regulations
            of a functional NGO and file our annual returns to the NGO Board as
            well as our partners.
          </p>
        </div>
        <div className={Styles.bodytext3}>
          <SkeletonDisplay>
            <ImageWithLoader
              src="/whoweare/organisationalchart.png"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
        </div>
      </div>
    </div>
  );
};

export default GovernanceContent;
