import React from "react";
import Styles from "./styles.module.css";
import { Link } from "react-router-dom";
import ImageWithLoader from "../hooks/ImageWithLoader";


function MainMenu() {
  return (
    <div className={Styles.Navbar}>
      <div className={Styles.rightSide}>
        <Link to="/">
          <ImageWithLoader src="aboutus/logo.png" alt="Sustainable World Organization " />
        </Link>
      </div>
      <div className={Styles.leftSide}>
        <Link to="/">
          <h1>Sustainable World Organization </h1>
        </Link>

        <p>
          We are a multi-sectoral, multi-stakeholder, holistic, national
          development organisation dedicated to making development work for all,
          especially women, youth and vulnerable groups, enabling them achieve
          their full potential.
        </p>
      </div>
    </div>
  );
}

export default MainMenu;
